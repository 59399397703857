<template>
  <div class="container-fluid">
    <div class="info-top">
      <h6>
        Product Information
      </h6>
      <router-link class="custom-btn4" to="/products">
        <span>Back</span>
      </router-link>
    </div>
    <div class="row">
      <div class="col-lg-9 px-0 pr-lg-2 mb-2 mb-lg-0">
        <div v-if="productsList">
          <table class="table table-sm table-responsive-md table-bordered">
            <tr>
              <th>Product Code</th>
              <td>{{ productsList.product_code }}</td>
            </tr>
            <tr>
              <th>Name</th>
              <td>{{ productsList.product_name }}</td>
            </tr>
            <tr>
              <th>Category</th>
              <td>
                <span v-if="productsList.category"
                  >{{ productsList.category.category_code }} -
                  {{ productsList.category.category_name }}</span
                >
              </td>
            </tr>
            <tr>
              <th>Cost</th>
              <td>{{ this.$currency }} {{ productsList.product_cost }}</td>
            </tr>
            <tr>
              <th>Price</th>
              <td>{{ this.$currency }} {{ productsList.product_price }}</td>
            </tr>
            <tr>
              <th>Quantity</th>
              <td>
                {{ productsList.product_quantity }}
                {{ productsList.product_unit }}
              </td>
            </tr>
            <tr>
              <th>Stock Worth</th>
              <td>
                COST:: {{ this.$currency }} {{ productsList.product_cost }} /
                PRICE:: {{ this.$currency }} {{ productsList.product_price }}
              </td>
            </tr>
            <tr>
              <th>Note</th>
              <td>{{ productsList.product_note }}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="col-lg-3 px-0">
        <div class="card h-100" v-if="productsList.picture == null">
          <img
            src="../../assets/images/product-image.png"
            alt=""
            class="img-fluid img-thumbnail mb-2"
          />
        </div>
        <div class="card h-100" v-else>
          <img
            :src="API_URL + productsList.picture"
            alt=""
            class="img-fluid img-thumbnail mb-2"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SeeProduct",
  data() {
    return {
      API_URL: this.$productStorageURL,
    };
  },
  computed: {
    ...mapGetters("product", ["productsList"]),
  },

  mounted() {
    console.log(this.productsList);
  },
};
</script>

<style></style>
